import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'
import helpers from '@/dc-it/services/helpers'

export default class Refund extends Model {
    endpoint ='refunds'

    fields = [
      new Field('id', 'Código').disabled().noFilterable(),
      new Field('token', 'Código de uso'),
      new Field('amount', 'Monto', 'number').applyMask(val => `$${helpers.numberFormat(val)}`).noFilterable(),
      new Field('created_at', 'Fecha de creación', 'date').applyMask(val => {
        const date = new Date(val)
        return helpers.dateFormat(date)
      }),
      new Field('max_date', 'Fecha de vencimiento', 'date'),
    ]

    clone = () => Refund;
}
