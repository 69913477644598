<template>
  <div>
    <table-component
      :model="model"
      :permissions="permissions"
    />
  </div>
</template>

<script>
import TableComponent from '@/dc-it/components/TableComponent.vue'
import Refund from '@/dc-it/models/Refund'

export default {
  name: 'RefundsView',
  components: { TableComponent },
  data() {
    return {
      model: Refund,
      permissions: {
        create: false,
        update: false,
        delete: false,
        filter: true,
      },
    }
  },
}
</script>

<style scoped>

</style>
